export const NETWORK_ERROR = 'NetworkError';

export class NetworkError extends Error {
  name = NETWORK_ERROR;
}

export const SERVER_ERROR = 'ServerError';

export class ServerError extends Error {
  name = SERVER_ERROR;
}

export const SESSION_EXPIRED = 'SessionExpiredError';

export class SessionExpiredError extends Error {
  name = SESSION_EXPIRED;
}

export const TIMEOUT_ERROR = 'TimeoutError';

export class TimeoutError extends Error {
  name = TIMEOUT_ERROR;
}

export const MAINTENANCE_ERROR = 'MaintenanceError';

export class MaintenanceError extends Error {
  name = MAINTENANCE_ERROR;
}

export const ABORT_ERROR = 'AbortError';

export const OUTDATED_ERROR = 'OutdatedError';
