import getJsonOrError from './getJsonOrError';

const getUserSettings = ({ apiUrl, brandId, token }) =>
  fetch(`${apiUrl}/api/v2/auth/brand/${brandId}/identify`, {
    headers: {
      'Content-Type': 'application/json',
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  }).then(getJsonOrError);

export default getUserSettings;
