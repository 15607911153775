import getJsonOrError from './getJsonOrError';

const getBrandSettings = ({ apiUrl, brandId, lang }) =>
  fetch(`${apiUrl}/api/v2/auth/brand/${brandId}/settings?lang=${lang}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(getJsonOrError);

export default getBrandSettings;
