import getUserSettings from '../api/getUserSettings';
import getBrandSettings from '../api/getBrandSettings';
import getToken from '../api/getToken';
import pRetry from './promiseRetry';

export const fetchSettings = (
  { apiUrl, brand_id: brandId, key, token, lang },
  { signal, onFailedAttempt }
) =>
  pRetry(
    () =>
      getAuthToken({ apiUrl, brandId, key, token }).then((token) =>
        fetchBrandOrUserSettings({ apiUrl, brandId, token, lang })
      ),
    {
      signal,
      onFailedAttempt,
      retries: 1000,
      maxTimeout: 60000,
    }
  );

const fetchBrandOrUserSettings = ({ apiUrl, brandId, token, lang }) =>
  (token ? getUserSettings : getBrandSettings)({
    apiUrl,
    brandId,
    token,
    lang,
  }).then((settings) => ({ brandId, token, ...settings }));

const getAuthToken = ({ apiUrl, brandId, token, key }) => {
  // new authentication: customer passes its own token throw options
  if (token) return Promise.resolve(token);

  // user is not logged in
  if (!key) return Promise.resolve();

  // old authentication: get token by key
  return getToken(apiUrl, brandId, key);
};
