const loadPolyfills = () => {
  const promises = [];

  if (!Array.prototype.flat) {
    promises.push(import('array-flat-polyfill'));
  }

  if (!window.AbortController) {
    promises.push(import('abortcontroller-polyfill/dist/polyfill-patch-fetch'));
  }

  return Promise.all(promises);
};

loadPolyfills();
