import getJsonOrError from './getJsonOrError';

const getToken = (serverUrl, brandId, key) =>
  fetch(`${serverUrl}/api/v2/auth/brand/${brandId}/authorize`, {
    method: 'POST',
    body: JSON.stringify({
      key,
    }),
  }).then((res) => (res.status === 400 ? '' : getJsonOrError(res)));

export default getToken;
