import {
  MaintenanceError,
  ServerError,
  SessionExpiredError,
} from '../utils/errors';

const getJsonOrError = (res) =>
  res.status === 400 || res.ok
    ? res.json()
    : res.status === 401
    ? Promise.reject(new SessionExpiredError())
    : res.status === 503
    ? res
        .json()
        .catch(() => Promise.reject(new ServerError()))
        .then((r) =>
          Promise.reject(
            r.maintenance ? new MaintenanceError() : new ServerError()
          )
        )
    : Promise.reject(new ServerError());

export default getJsonOrError;
